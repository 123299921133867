import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import PageBuilder from '../../components/common/PageBuilder/PageBuilder'
import PageIntro from '../../components/common/PageIntro'
import { Helmet } from 'react-helmet'

export const query = graphql`
	query NewsQuery($Slug: String!) {
		strapiBlogs(Slug: { eq: $Slug }) {
			Slug
			PageBuilder
			Title
			Summary
		}
	}
`

const Article = ({ data }) => {
	const pageData = data.strapiBlogs
	return (
		<Layout>
			<Helmet>
				<title>Delta Insurance | {pageData.Title}</title>
				<meta name="description" content={pageData.Summary} />
			</Helmet>
			<h1 className="visually-hidden">{pageData.Title}</h1>
			<div className="page-container max-width-900">
				<PageBuilder data={pageData.PageBuilder} />
			</div>
		</Layout>
	)
}

export default Article
